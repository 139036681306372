
import { defineComponent, ref, computed, watch, nextTick } from 'vue';
import { CloseOutlined, LeftOutlined, RightOutlined, ZoomOutOutlined, ZoomInOutlined, UndoOutlined, RedoOutlined } from '@ant-design/icons-vue';

type transformType = {
    scale: number, 
    deg: number,
    enableTransition: boolean,
}

const transformDefault = {
  scale: 1, // 缩放
  deg: 0, // 旋转度数
  enableTransition: false, // 渐变
};

export default defineComponent({
  name: 'ImageViewer',
  components: { CloseOutlined, LeftOutlined, RightOutlined, ZoomOutOutlined, ZoomInOutlined, UndoOutlined, RedoOutlined },
  props: {
    urlList: {
      type: Array,
      default: () => ([]),
    },
    zIndex: {
      type: Number,
      default: 2000,
    },
    initialIndex: {
      type: Number,
      default: 0,
    },
    onClose: {
      type: Function,
      default: () => ({}),
    },
  },
  emits: ['update:visible'],
  setup(props, ctx) {
    const index = ref(props.initialIndex);
    const transform = ref<transformType>({
      ...transformDefault,
    });
    const loading = ref(false);
    const isFirst = computed(() => index.value === 0);
    const isLast = computed(() => index.value === props.urlList.length - 1);
    const currentImg = computed(() => props.urlList[index.value]);
    const isSingle = computed(() => props.urlList.length <= 1);
    const imgStyle = computed(() => {
      const style = {
        transform: `scale(${transform.value.scale}) rotate(${transform.value.deg}deg)`,
        transition: transform.value.enableTransition ? 'transform .3s' : '',
      };
      return style;
    });

    watch(() => index.value, newValue => {
      transform.value = { ...transformDefault };
    });

    const img = ref();
    watch(() => currentImg.value, newValue => {
      nextTick(() => {
        if (!img.value.complete) {
          loading.value = true;
        }
      });
    });

    const handleImgLoad = (e: any) => {
      loading.value = false;
    };

    const closeViewer = () => {
      ctx.emit('update:visible', false);
    };

    const hide = () => {
      props.onClose();
    };

    const prev = () => {
      const len = props.urlList.length;
      index.value = (index.value - 1 + len) % len;
    };
    const next = () => {
      const len = props.urlList.length;
      index.value = (index.value + 1) % len;
    };
    const handleActions = (action: string, options = {}) => {
      if (loading.value) return;
      const { zoomRate, rotateDeg, enableTransition } = {
        zoomRate: 0.2,
        rotateDeg: 90,
        enableTransition: true,
        ...options,
      };
      switch (action) {
        case 'zoomOut': // 放小
          if (transform.value.scale > 0.2) {
            transform.value.scale = parseFloat((transform.value.scale - zoomRate).toFixed(3));
          }
          break;
        case 'zoomIn': // 放大
          transform.value.scale = parseFloat((transform.value.scale + zoomRate).toFixed(3));
          break;
        case 'clocelise': // 向左旋转
          transform.value.deg += rotateDeg;
          break;
        case 'anticlocelise': // 向右旋转
          transform.value.deg -= rotateDeg;
          break;
        default:
          break;
      }
      transform.value.enableTransition = enableTransition;
    };

    return {
      index,
      isFirst,
      isLast,
      currentImg,
      isSingle,
      imgStyle,
      img,
      handleImgLoad,
      closeViewer,
      hide,
      prev,
      next,
      handleActions,
    };
  },
});


