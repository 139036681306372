import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "el-image" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_image_viewer = _resolveComponent("image-viewer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "content"),
    (_ctx.preview)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.visible)
            ? (_openBlock(), _createBlock(_component_image_viewer, {
                key: 0,
                "z-index": _ctx.zIndex,
                "initial-index": _ctx.imageIndex,
                "on-close": _ctx.closeViewer,
                "url-list": _ctx.previewSrcList
              }, null, 8, ["z-index", "initial-index", "on-close", "url-list"]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}