
import { defineComponent, ref, computed } from 'vue';
import ImageViewer from './image-viewer.vue';

export default defineComponent({
  name: 'AddCarModal',
  components: {
    ImageViewer,
  },
  props: {
    previewSrcList: { 
      type: Array,
      default: () => ([]),
    },
    visible: {
      type: Boolean,
      default: false,
    },
    zIndex: {
      type: Number,
      default: 2000,
    },
    imageIndex: { 
      type: Number,
      default: 0,  
    },
  },
  emits: ['update:visible'],
  setup(props, ctx) {
    const preview = computed(() => Array.isArray(props.previewSrcList) && props.previewSrcList.length > 0);

    const closeViewer = () => {
      ctx.emit('update:visible', false);
    };

    return {
      preview,
      closeViewer,
    };
  },
});


